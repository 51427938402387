import {
	Box,
	Button,
	Card,
	Container,
	Divider,
	Flex,
	Group,
	Image,
	LoadingOverlay,
	Paper,
	SimpleGrid,
	Stack,
	Table,
	Text,
	Title,
	useMantineTheme,
} from '@mantine/core';

import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import useProvider from '../../hooks/use-provider';
import AudioPlayer from './audio/audio-player';
import { useBooking } from '../../hooks/use-booking';
import { convertBaseToString } from '../../utils';
import { useGuardedNavigation } from '../../pages/navigation';
import Cookies from 'js-cookie';
import useProfile from '../../hooks/use-profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { routes } from '../../api/routes';
import useSummary from '../../hooks/use-summary';
import {
	ICreateConsultationResponse,
	IFollowUpResponse,
	IPaymentInfo,
	IPaystackPayment,
	IStripePayment,
} from '../../api/types';
import PaymentSelect from '../payment/select/payment-select';
import styles from '../payment/select/payment-option.module.css';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import { IconCaretLeft } from '@tabler/icons-react';
import { useStateMachine } from 'little-state-machine';
import useChildren from '../../hooks/use-children';
import { setChildId } from '../../consultation-state';
import { showNotification } from '@mantine/notifications';
import { date } from 'zod';
import { useEffect, useState } from 'react';
import FollowUpItem from '../summary/followup-item';
import { useLocation } from 'react-router-dom';
import useConsultationPrice from '../../hooks/use-consultation-price';
import PaymentSelectFollowUp from '../payment/select/payment-select-followup';
import axios from 'axios';
import { env } from '../../utils/env';

type SummaryProps = {
	followUpId: number;
	previousStep: () => void;
	priceListName: string; // Add priceListName to the props
};

const currentDate = new Date().toJSON().slice(0, 10);

const TextWithDescription = ({
	title,
	description,
}: {
	title: string;
	description?: ReactNode;
}) => {
	return (
		<Stack spacing={0} align="start" w="inherit">
			<Text size="lg" weight={500} color="dimmed">
				{title}
			</Text>
			{typeof description === 'string' ? (
				<Text align="start">{description}</Text>
			) : (
				description
			)}
		</Stack>
	);
};
const persistPaymentStateToCookies = ({
	id,
	url,
}: {
	id: string;
	url: string;
}) => {
	Cookies.set(id, url, { expires: 24 });
};

const persistPaymentInfoToCookies = ({
	paymentInfo,
}: {
	paymentInfo: IPaymentInfo;
}) => {
	Cookies.set('tr_info', JSON.stringify(paymentInfo), { expires: 1 });
};

interface FollowUpBookingSummaryProps {
	consultationId?: number;
	id?: number;
}

const FollowUpBookingSummary = ({
	consultationId,
	id,
}: FollowUpBookingSummaryProps) => {
	const { breakpoints } = useMantineTheme();
	const queryClient = useQueryClient();
	const isSmall = useMediaQuery(`(max-width: ${breakpoints.xs}px)`);
	const { booking, actions } = useBooking();
	const { provider } = useProvider();
	const { user } = useProfile();
	const router = useGuardedNavigation();
	const { getSummaryById, getFollowUpSummary, getFollowUpById } = useSummary();

	const formattedDate = (dateString?: string | Date) => {
		if (!dateString) return 'Invalid date';

		const date = new Date(dateString);
		return `${date.toDateString().replace(' ', ', ')} ${date.toLocaleTimeString(
			'en-US',
			{
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: true,
			}
		)}`;
	};

	const result = formattedDate('2024-09-01T11:13:05.046Z');
	console.log(result);

	const { summaries, loading } = useSummary();
	const { data: getSummary } = getSummaryById(consultationId);
	// const {data:follow} = getFollowUpSummary(consultationId);
	const { data: follow } = getFollowUpById(consultationId);

	const {
		state: { consultation },
	} = useStateMachine({ setChildId });
	const { child } = useChildren(consultation.childId);

	const { mutateAsync: createConsultation, isLoading } = useMutation(
		routes.createConsultation,
		{
			onSuccess: () => {
				void queryClient.invalidateQueries(['getProfile']);
			},
			onError: () => {
				showNotification({
					title: t('tr.error'),
					message: t('tr.error-occured-booking'),
					color: 'red',
				});
			},
		}
	);

	//use mantoine to check if hes an adult or not
	//how? -> figure it out later

	// const isfollowUp = true;
	const followUpAdult = useConsultationPrice('followup');

	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const followUp = (summaries || [])
		.map((sum) => sum.followUp)
		.filter((sum) => sum !== null);

	const dataPased = () => {
		console.log(followUp);
	};

	const { t } = useTranslation(['default']);
	const location = useLocation();

	const calculateAmountToPay = (
		wallet: number,
		followUpAdult: { priceList?: { price?: number } }
	): number => {
		const followUpPrice = followUpAdult.priceList?.price || 0; // Get the follow-up price, default to 0 if undefined

		if (wallet >= followUpPrice) {
			return 0; // Wallet covers the full amount, so no payment is required
		} else {
			return followUpPrice - wallet; // Return the amount that still needs to be paid
		}
	};

	return (
		<Container>
			<LoadingOverlay visible={isLoading} />

			<Flex className={styles.flexWrapper} direction="row">
				<Paper radius="md" p="sm" color="blue" pb="sm" shadow="md">
					<Card.Section>
						<Group position="apart" noWrap={true}>
							<Stack spacing="xs" pt="xl" align="start">
								<Title
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.heading.subheading.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('tr.healthcare-provider').toUpperCase()}
								</Title>
								<Text
									style={{
										fontFamily: mantineConfig.mantine.global.fontFamily,
									}}
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.label.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{provider?.providerName}
								</Text>
							</Stack>
							<Text
								pr="xl"
								style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
								color={mantineConfig.mantine.text.color}
								size={mantineConfig.mantine.text.fontSize}
							>
								{currentDate}
							</Text>
						</Group>
					</Card.Section>

					<Card.Section mt="md" pl="xl" pr="xl">
						<Group position="center">
							<Paper
								w={{ xs: '100%', sm: '60%', md: 650 }}
								withBorder={true}
								radius={{ xs: 'sm', sm: 'md', md: 'lg' }}
								shadow="sm"
							>
								<Table
									withColumnBorders={true}
									style={mantineConfig?.mantine?.table?.container}
								>
									<tbody>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.your-account-balance').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text className="responsive-table-text" weight="bold">
													{user?.wallet ? user?.wallet.balance : 0}{' '}
													{provider?.currency}
												</Text>
											</td>
										</tr>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{'Follow Up cost'}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text className="responsive-table-text" weight="bold">
													{follow?.price.toString()}
												</Text>
											</td>
										</tr>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.amount-to-pay').toUpperCase()}
											</th>

											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text
													className="responsive-table-text text-bold"
													weight="bold"
												>
													<div>
														{calculateAmountToPay(
															user?.wallet ? user.wallet.balance : 0,
															followUpAdult
														)}{' '}
														{provider?.currency}
													</div>
												</Text>
											</td>
										</tr>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.duration').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text className="responsive-table-text" weight="bold">
													{followUpAdult.priceList?.duration} {t('tr.minutes')}
												</Text>
											</td>
										</tr>
									</tbody>
								</Table>
							</Paper>
						</Group>
					</Card.Section>

					<Divider my="md" />
					<Stack
						spacing="sm"
						pl="xl"
						pr="xl"
						align="start"
						sx={{ width: '100%' }}
					>
						<Fragment>
							<Title
								color={mantineConfig.mantine.title.color}
								size={mantineConfig.mantine.title.fontSize}
								weight={mantineConfig.mantine.title.fontWeight}
							>
								{t('tr.name')}
							</Title>
							<Text
								style={{
									fontFamily: mantineConfig.mantine.global.fontFamily,
								}}
								color={mantineConfig.mantine.text.color}
								size={mantineConfig.mantine.text.fontSize}
								weight={mantineConfig.mantine.text.fontWeight}
							>
								{' '}
								{`${user?.firstName} ${user?.surName}`}
							</Text>
						</Fragment>
						<Title
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>{`${t('tr.appointment-regarding')}`}</Title>
						<Text
							style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
							color={mantineConfig.mantine.text.color}
							size={mantineConfig.mantine.text.fontSize}
							weight={mantineConfig.mantine.text.fontWeight}
						>
							{' '}
							{`${follow?.purpose}`}
						</Text>

						<Title
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>{`${t('Follow up Date')}`}</Title>
						<Text
							style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
							color={mantineConfig.mantine.text.color}
							size={mantineConfig.mantine.text.fontSize}
							weight={mantineConfig.mantine.text.fontWeight}
						>
							{' '}
							{formattedDate(follow?.followUpDate)}
							{/* {`${follow?.followUpDate}`} */}
						</Text>
					</Stack>
					<Divider my="md" className={styles.goHide} />
				</Paper>
				<PaymentSelect previousStep={follow} />
			</Flex>
		</Container>
	);
};

const CreatePaymentInfo = async (data: ICreateConsultationResponse) => {
	//Create paymentInfo object
	const paymentInfo: IPaymentInfo = {
		paymentProvider: data.paymentProvider,
		referenceId: data.referenceId,
		data: null,
	};

	//Create objects depending on paymentProvider and set to paymentInfo
	switch (paymentInfo.paymentProvider) {
		case 'paystack': {
			const paystack: IPaystackPayment = {
				transactionUrl: data.transactionUrl,
			};

			paymentInfo.data = paystack;
			break;
		}
		case 'Stripe': {
			const stripe: IStripePayment = {
                amount: data.amount,
                currency: env.VITE_DEFAULT_CURRENCY,
                successUrl: env.VITE_LOGIN_URL,
                cancelUrl: env.VITE_LOGIN_URL,
                productId: data.productId,
            };

            paymentInfo.data = stripe;

            try {
                const response = await axios.post(`${env.VITE_STRIPE_API_URL}/api/Stripe/process`, stripe);
                console.log('Stripe payment processed successfully:', response.data);
            } catch (error) {
                console.error('Error processing Stripe payment:', error);
            }
			break;
		}
		case 'wallet':
			break;
		default:
			console.log('error: ' + paymentInfo.paymentProvider);
			break;
	}

	return paymentInfo;
};

export default FollowUpBookingSummary;

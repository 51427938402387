import { Button, Flex, Group, Modal, Paper, Stack, Text } from '@mantine/core';
import { useState, useEffect } from 'react';
import config from './config.json'; //Should come from server
import PaymentSelectOption from './payment-select-option';
import { useTranslation } from 'react-i18next';
import { useBooking } from '../../../hooks/use-booking';
import { convertBaseToString } from '../../../utils';
import {
	showNotification,
	NotificationType,
} from '../../../utils/notification';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { routes } from '../../../api/routes';
import { useGuardedNavigation } from '../../../pages/navigation';
import {
	ICreateConsultationResponse,
	IPaymentInfo,
	IPaystackPayment,
	IStripePayment,
} from '../../../api/types';
import Cookies from 'js-cookie';
import styles from './payment-option.module.css';
import mantineConfig from '../../../assets/styles/config/mantine.config.json';
import { IconAlertCircle, IconCaretLeft } from '@tabler/icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faFileShield } from '@fortawesome/free-solid-svg-icons';
import { useStateMachine } from 'little-state-machine';
import { setChildId } from '../../../consultation-state';
import useConsultationPrice from '../../../hooks/use-consultation-price';
import useProfile from '../../../hooks/use-profile';
import { useDiscount } from '../../../hooks/use-discount';
import axios from 'axios';
import { env } from '../../../utils/env';
import { processStripePayment } from '../../../pages/booking/payment';
import { calculateTotalAmount } from '../../booking/booking-summary';
import { notifications } from '@mantine/notifications';

/*
	Only logic implemented, styling to be done later.
*/

const PaymentSelect = ({ previousStep }) => {
	// const PaymentSelect = () => {

	const router = useGuardedNavigation();
	const queryClient = useQueryClient();
	const { booking, actions } = useBooking();
	const followUpPriceList = useConsultationPrice('followup');
	const { t } = useTranslation(['default']);
	const [paymentOptions, setPaymentOptions] = useState<string[]>();
	const [paymentProvider, setPaymentProvider] = useState<string>();
	const { user } = useProfile();
	const { discountedAmount } = useDiscount();

	const isTelegram = Cookies.get('isTelegram') === 'true';

	const [isModalOpen, setIsModalOpen] = useState(false);

	const clearCookies = () => {
		const cookies = document.cookie.split(';');
		cookies.forEach((cookie) => {
			const name = cookie.split('=')[0].trim();
			document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
		});
	};

	const handleLoginClick = () => {
		clearCookies();
		setIsModalOpen(false);
		window.location.href = import.meta.env.VITE_LOGIN_URL;
	};

	const handleCreateClick = () => {
		clearCookies();
		setIsModalOpen(false);
		window.location.href = 'https://telegram.org/';
	};

	const {
		state: { consultation },
	} = useStateMachine({ setChildId });

	const { mutateAsync: createConsultation, isLoading } = useMutation(
		routes.createConsultation,
		{
			onSuccess: () => {
				void queryClient.invalidateQueries(['getProfile']);
			},
			onError: () => {
				showNotification(NotificationType.BookingError, t);
			},
		}
	);

	//Simulate api call, x ms delay
	useEffect(() => {
		const fetchData = async () => {
			await new Promise((r) => setTimeout(r, 1000));
			setPaymentOptions(['Wallet', 'Paystack', 'Paypal', 'Stripe']);

			// Set the default payment provider here
			setPaymentProvider('Wallet'); // Assuming 'Paystack' is the default payment provider
		};

		fetchData();
	}, []);

	const handlePayment = async () => {
		if (!isTelegram) {
			setIsModalOpen(true);
			return; // Prevent further actions if the modal is open
		}
		try {
			let byteArray: Uint8Array = new Uint8Array(0);

			if (booking.audioDetailedDescription) {
				byteArray = convertBaseToString(booking.audioDetailedDescription);
			}
			const amount = booking.consultationType.appliedDiscount
				? booking.consultationType.appliedDiscount.discountedPrice
				: booking.consultationType.price;

			const vatPercentage = booking.consultationType.vat;
			const currency = env.VITE_DEFAULT_CURRENCY;
			const totalAmount = calculateTotalAmount(amount, vatPercentage, currency);

			const consultationVenueType =
				booking.consultationType.venue.toUpperCase();

			await createConsultation(
				{
					selfDiagnose: {
						bodyArea: booking.bodyArea,
						images: booking.images,
						textDetailedDescription: booking.textDetailedDescription,
						audioDetailedDescription: [...byteArray],
					},
					appointment: {
						appointedDoctorEmail: booking.selectedDate?.email || null,
						appointmentTime: new Date().toISOString(),
						childUserId: booking.childId,
						consultationVenueType: consultationVenueType,
						isFollowup: false,
					},
					product: {
						commission: booking.consultationType.commission,
						duration: booking.consultationType.duration,
						price: totalAmount,
						productId: booking.consultationType.id,
						vat: booking.consultationType.vat,
						typeOfConsultation: booking.consultationType.speciality.name,
						productSpecialitiesIds: booking.consultationType.speciality.id,
						isDropIn: booking.consultationType.isDropIn,
					},
					payment: {
						amountPaid: totalAmount,
						paymentProvider: paymentProvider as string,
					},
					followUpId: booking.followupId,
				},
				{
					onSuccess: (data) => {
						showNotification(NotificationType.BookingSuccess, t);

						actions.reset();

						const paymentInfo = CreatePaymentInfo(data);

						console.log('paymentInfo------', paymentInfo);

						persistPaymentInfoToCookies(paymentInfo);

						if (paymentInfo.paymentProvider === 'Stripe') {
							processStripePayment(paymentInfo.data as IStripePayment);
						} else {
							router.navigate('payment');
						}
					},
				}
			);

			// ); // Set a timeout for 30 seconds
			const timeoutId = setTimeout(() => {
				// Show notification to reload if payment not redirected
				showNotification(NotificationType.ReloadPage, t);
			}, 10000);

			// Clear the timeout if payment is successful and redirected
			return () => clearTimeout(timeoutId);
		} catch (error) {
			notifications.show({
				title: 'Payment Error',
				message:
					'Paystack is not supported in your country. Please try using another payment method.', // Popup message
				color: 'red',
			});
		}
	};

	//map response from api with name, color and image
	const mappedPaymentOptions = paymentOptions?.map((paymentOptionName) => {
		//Get config for payment option by name
		const paymentOptionConfig = config.find(
			(x) => x.name.toLowerCase() === paymentOptionName.toLowerCase()
		);

		return (
			<PaymentSelectOption
				key={paymentOptionConfig?.name as string}
				name={paymentOptionConfig?.name as string}
				/* color="#FFFFFF"  */
				color={paymentOptionConfig?.color as string}
				icon={paymentOptionConfig?.icon as string}
				setPaymentProvider={setPaymentProvider}
				selected={paymentProvider === paymentOptionConfig?.name}
			/>
		);
	});

	return (
		<Paper
			radius="md"
			p="xl"
			color="blue"
			shadow="md"
			className={styles.paymentOptions}
		>
			<Text
				p="md"
				pl="0"
				style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
				color={mantineConfig.mantine.text.color}
				size={mantineConfig.mantine.text.payment.fontSize}
				weight={mantineConfig.mantine.text.fontWeight}
			>
				<FontAwesomeIcon
					icon={faFileShield}
					beatFade
					size="sm"
					style={{ color: '#05a98b' }}
				/>{' '}
				{t('tr.payment-options')}{' '}
				<FontAwesomeIcon
					icon={faCircleCheck}
					size="sm"
					style={{ color: '#05a98b' }}
				/>
			</Text>

			<Stack justify="flex-start" className={styles.paymentOptionContainer}>
				{paymentOptions ? (
					mappedPaymentOptions
				) : (
					<p
						style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
						color={mantineConfig.mantine.text.color}
					>
						{t('tr.is-loading')}
					</p>
				)}
			</Stack>

			<Flex
				direction="row"
				justify="space-between"
				align="center"
				mt="xs"
				pt="md"
			>
				<Button
					onClick={previousStep}
					leftIcon={
						<IconCaretLeft size={mantineConfig.mantine.button.iconSize} />
					}
					color={mantineConfig.mantine.button.back.backgroundColor}
					className={styles.goHideDesktop}
					style={{
						color: mantineConfig.mantine.button.fontColor,
					}}
				>
					{`${t('tr.back').toUpperCase()}`}
				</Button>
				<Button
					className={styles.payBtn}
					color={mantineConfig.mantine.button.backgroundColor}
					disabled={!paymentProvider || isLoading}
					sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
					onClick={() => handlePayment()}
				>
					{`${t('tr.pay-now').toUpperCase()}`}
				</Button>
				<Modal
					opened={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					withCloseButton={false} // Hides the default close button
					centered // Center the modal in the middle of the page
					closeOnClickOutside={true} // Disable closing on outside click
					overlayOpacity={0.55}
					overlayBlur={3}
					styles={{
						content: {
							backgroundColor: '#f0f4f8',
							padding: '2rem',
							borderRadius: '15px',
							width: '900px', // Set custom width to make the modal more rectangular
							height: '350px', // Optionally set a specific height for the rectangle shape
						},
						inner: {
							// Ensure the modal is centered properly
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					}}
				>
					<Group position="center" mb="md">
						<IconAlertCircle size={50} color="#1c7ed6" />
					</Group>

					<Text align="center" weight={700} size="xl" mb="sm">
						Sign in with Telegram!
					</Text>

					<Text align="center" size="md" color="dimmed" mb="lg">
						To access all features, please sign in with your Telegram account or
						create a new one to continue.
					</Text>

					<Group position="apart" mt="md" style={{ width: '100%' }}>
						{/* Buttons spaced apart */}
						<Button
							size="lg"
							radius="xl"
							variant="gradient"
							gradient={{ from: 'teal', to: 'lime', deg: 105 }}
							onClick={handleCreateClick} // Clear cookies and open the link in a new tab
						>
							Create
						</Button>
						<Button
							size="lg"
							radius="xl"
							variant="gradient"
							gradient={{ from: 'blue', to: 'cyan', deg: 105 }}
							onClick={handleLoginClick} // Clear cookies and then redirect to login URL
						>
							Log In
						</Button>
					</Group>
				</Modal>
			</Flex>
		</Paper>
	);
};

const persistPaymentInfoToCookies = (paymentInfo: IPaymentInfo) => {
	console.log('Persisting payment info to cookies:', paymentInfo);
	if (paymentInfo && paymentInfo.paymentProvider) {
		Cookies.set('payment_info', JSON.stringify(paymentInfo), { path: '/' });
	} else {
		console.error('Invalid paymentInfo data:', paymentInfo);
	}
};

const CreatePaymentInfo = (data: ICreateConsultationResponse): IPaymentInfo => {
	//Create paymentInfo object
	const paymentInfo: IPaymentInfo = {
		paymentProvider: data.paymentProvider,
		referenceId: data.referenceId,
		data: null,
	};

	switch (paymentInfo.paymentProvider) {
		case 'paystack':
			paymentInfo.data = {
				transactionUrl: data.transactionUrl,
			} as IPaystackPayment;
			break;

		case 'Stripe':
			paymentInfo.data = {
				amount: data.amount,
				currency: env.VITE_DEFAULT_CURRENCY,
				successUrl: env.VITE_SELF_DOMAIN,
				cancelUrl: env.VITE_SELF_DOMAIN,
				productId: data.productId,
				consultationId: data.consultationId,
			} as IStripePayment;
			break;

		case 'wallet':
			break;

		default:
			console.error('Unknown payment provider: ' + paymentInfo.paymentProvider);
			break;
	}

	return paymentInfo;
};

export default PaymentSelect;

import { useState, useEffect } from 'react';
import {
	Grid,
	SimpleGrid,
	Space,
	Loader,
	Alert,
	Pagination
} from '@mantine/core';
import Styles from '../../assets/styles/pages/home.module.css';
import Container from '../../components/layout/container';
import { useBooking } from '../../hooks/use-booking';
import { ISpecialityData, useSpeciality } from '../../context/speciality';
import ConsultationCard from '../../components/core/consultation-card';

// Consultation interface
interface Consultation {
	id: string;
	name: string;
	duration: string;
	specialities: string[];
	virtual: boolean;
	imageName: string;
}

const ConsultationType = ({
	nextStep,
	previousStep,
	showNext = true,
	forWho,
}: {
	nextStep: (step?: number) => void;
	previousStep: (step?: number) => void;
	showNext: boolean;
	forWho: 'Patient' | 'Child' | undefined;
}) => {
	const actualForWho = forWho ?? 'Patient';

	const { actions, booking } = useBooking();
	const [retryCount, setRetryCount] = useState(0);
	const maxRetries = 3;
	const retryDelay = 2000;

	const [currentPage, setCurrentPage] = useState(1);

	const { specialities, loading, error } = useSpeciality(
		actualForWho,
		retryCount
	);

	const ConsultationsPerPage = 6;
	const startIndex = (currentPage - 1) * ConsultationsPerPage;
	const paginatedConsultations = specialities.slice(
		startIndex,
		startIndex + ConsultationsPerPage
	);

	const handleNext = (data: ISpecialityData) => {
		actions.handleAddType({
			id: data.id,
			type: data.careServiceName,
			speciality: data.requiredSpecialities[0] || '',
			duration: data.duration,
			price: data.price,
			commission: data.commission,
			vat: data.vat,
			venue: data.venue[0] || '',
			availableTimes: data.availableTimes,
			isDropIn: data.isDropin,
			appliedDiscount: data.appliedDiscount
		});

		// A fixed id for dropin 
		if (data.isDropin) return nextStep(2);

		nextStep();
	};

	useEffect(() => {
		if (error && retryCount < maxRetries) {
			const retryTimeout = setTimeout(() => {
				setRetryCount((prev) => prev + 1);
			}, retryDelay);

			return () => clearTimeout(retryTimeout);
		}
	}, [error, retryCount]);

	return (
		<>
			{showNext ? (
				<Container>
					<Space h="xl" />
					{/* Error message if retries are exhausted */}
					{!loading && error && retryCount >= maxRetries && (
						<Alert title="Error" color="red" withCloseButton>
							Failed to load specialities after multiple attempts. Please check
							your connection or try again later.
						</Alert>
					)}

					{loading && <Loader />}

					{!loading && !error && (
						<>
							<Grid className={Styles['flex-container-one']} justify="center">
								<SimpleGrid
									cols={3}
									spacing="xl"
									breakpoints={[
										{ maxWidth: 625, cols: 1, spacing: 'md' },
										{ maxWidth: 1000, cols: 2, spacing: 'md' },
									]}
								>
									{paginatedConsultations.map((consultation) => (
										<ConsultationCard
											key={consultation.id}
											title={consultation.careServiceName}
											onClick={() => handleNext(consultation)}
											description={consultation.description}
											badgeLabel={consultation.duration + ' min' + ' | Virtual'}
										/>
									))}
								</SimpleGrid>
							</Grid>
							<Space h="xl" />

							{/* Pagination Component */}
							<Pagination
								total={Math.ceil(specialities.length / ConsultationsPerPage)}
								value={currentPage}
								onChange={setCurrentPage}
								color="teal"
								size="lg"
								withEdges
							/>
							<Space h="xl" />
							<Space h="md" />
						</>
					)}
				</Container>
			) : null}
		</>
	);
};

export default ConsultationType;

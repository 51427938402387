import {
	createContext,
	useContext,
	useEffect,
	useState,
	ReactNode,
} from 'react';
import { routes } from '../api/routes';

interface IAvailableTimes {
	doctor: string;
	endTime: string;
	startTime: string;
}

export interface ISpecialityData {
	id: string;
	careServiceName: string;
	clientType: string[];
	commission: number;
	description: string;
	appliedDiscount: {
		discountId: string;
		discountName: string;
		discountedPrice: number;
		discountPercentage: number;
		appliedAt: Date;
		expiryDate: Date;
	};
	doctorAvailable: boolean;
	eligibleDoctorEmails: string[];
	duration: number;
	isFollowUp: boolean;
	price: number;
	requiredSpecialities: string[];
	vat: number;
	venue: string[];
	availableTimes: IAvailableTimes[];
	isDropin: boolean;
}

interface ISpecialityContext {
	getSpecialities: (
		clientType: 'Child' | 'Patient'
	) => Promise<ISpecialityData[]>;
}

const SpecialityContext = createContext<ISpecialityContext | null>(null);

export const SpecialityProvider = ({ children }: { children: ReactNode }) => {
	const getSpecialities = async (clientType: 'Child' | 'Patient') => {
		try {
			const data: ISpecialityData[] = await routes.getSpeciality(clientType);
			return data;
		} catch (err) {
			console.error('Failed to fetch specialities', err);
			throw new Error('Failed to load specialities');
		}
	};

	return (
		<SpecialityContext.Provider value={{ getSpecialities }}>
			{children}
		</SpecialityContext.Provider>
	);
};

export const useSpeciality = (
	clientType: 'Child' | 'Patient' | undefined,
	retryCount: number
) => {
	const actualClientType = clientType ?? 'Patient';
	const context = useContext(SpecialityContext);
	if (context === null) {
		throw new Error('useSpeciality must be used within a SpecialityProvider');
	}

	const { getSpecialities } = context;

	const [specialities, setSpecialities] = useState<ISpecialityData[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		let isSubscribed = true;
		setLoading(true);
		setError(null);

		getSpecialities(actualClientType)
			.then((data) => {
				if (isSubscribed) {
					setSpecialities(data);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isSubscribed) {
					setError(err.message || 'Failed to load specialities');
					setLoading(false);
				}
			});

		return () => {
			isSubscribed = false;
		};
	}, [clientType, retryCount, getSpecialities]);

	return { specialities, loading, error };
};

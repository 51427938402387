import {
	Button,
	Card,
	Container,
	Divider,
	Flex,
	Group,
	Image,
	Paper,
	SimpleGrid,
	Stack,
	Table,
	Text,
	Title,
	useMantineTheme,
} from '@mantine/core';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import useProvider from '../../hooks/use-provider';
import AudioPlayer from './audio/audio-player';
import { useBooking } from '../../hooks/use-booking';
import { convertBaseToString } from '../../utils';
import { useGuardedNavigation } from '../../pages/navigation';
import Cookies from 'js-cookie';
import useProfile from '../../hooks/use-profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { routes } from '../../api/routes';
import {
	ICreateConsultationResponse,
	IPaymentInfo,
	IPaystackPayment,
	IStripePayment,
} from '../../api/types';
import PaymentSelect from '../payment/select/payment-select';
import styles from '../payment/select/payment-option.module.css';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import { IconCaretLeft } from '@tabler/icons-react';
import { useStateMachine } from 'little-state-machine';
import useChildren from '../../hooks/use-children';
import { setChildId } from '../../consultation-state';
import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { env } from '../../utils/env';

type SummaryProps = {
	previousStep: () => void;
	// priceListName: string; // Add priceListName to the props
};

const currentDate = new Date().toJSON().slice(0, 10);
export const calculateTotalAmount = (
	amount: number,
	vatPercentage: number,
	currency: any
) => {
	const vat = calculateVAT(amount, vatPercentage);
	const paystackFee = calculatePaystackFee(amount, currency);
	return amount + vat + paystackFee;
};

const calculateVAT = (amount: number, vatPercentage: number) => {
	return (amount * vatPercentage) / 100;
};

const calculatePaystackFee = (amount: number, currency: any) => {
	// List of supported currencies for Paystack fee calculation
	const supportedCurrencies = ['NGN', 'GHS', 'ZAR', 'KES'];

	// Check if the currency is supported
	if (!supportedCurrencies.includes(currency)) {
		return 0; // No Paystack fee for unsupported currencies
	}

	const baseFeePercentage = parseFloat(
		env.VITE_PAYSTACK_FEE_PERCENTAGE || '1.5'
	);
	const feePercentage = amount < 2500 ? 1.5 : baseFeePercentage;
	let fee = (amount * feePercentage) / 100;

	if (currency === 'NGN' && amount > 2500) {
		fee += 100;
	}

	return Math.min(fee, 2000);
};
const TextWithDescription = ({
	title,
	description,
}: {
	title: string;
	description?: ReactNode;
}) => {
	return (
		<Stack spacing={0} align="start" w="inherit">
			<Text size="lg" weight={500} color="dimmed">
				{title}
			</Text>
			{typeof description === 'string' ? (
				<Text align="start">{description}</Text>
			) : (
				description
			)}
		</Stack>
	);
};
const persistPaymentStateToCookies = ({
	id,
	url,
}: {
	id: string;
	url: string;
}) => {
	Cookies.set(id, url, { expires: 24 });
};

const persistPaymentInfoToCookies = ({
	paymentInfo,
}: {
	paymentInfo: IPaymentInfo;
}) => {
	Cookies.set('tr_info', JSON.stringify(paymentInfo), { expires: 1 });
};

const BookingSummary = ({ previousStep }: SummaryProps) => {
	const { breakpoints } = useMantineTheme();
	const queryClient = useQueryClient();
	const isSmall = useMediaQuery(`(max-width: ${breakpoints.xs}px)`);
	const { t } = useTranslation(['default']);
	const { booking, actions } = useBooking();
	const { provider } = useProvider();
	const { user } = useProfile();
	const router = useGuardedNavigation();
	const {
		state: { consultation },
	} = useStateMachine({ setChildId });
	const { child } = useChildren(consultation.childId);

	const { mutateAsync: createConsultation, isLoading } = useMutation(
		routes.createConsultation,
		{
			onSuccess: () => {
				void queryClient.invalidateQueries(['getProfile']);
			},
			onError: () => {
				showNotification({
					title: t('tr.error'),
					message: t('tr.error-occured-booking'),
					color: 'red',
				});
			},
		}
	);

	const handlePayment = async () => {
		try {
			let byteArray: Uint8Array = new Uint8Array(0);

			if (booking.audioDetailedDescription) {
				byteArray = convertBaseToString(booking.audioDetailedDescription);
			}
			await fetch('http://localhost:4242/create-payment-intent', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] }),
			})
				.then((res) => res.json())
				.then((data) => {
					//Fake backend response
					const createConsultationResponse: ICreateConsultationResponse = {
						bookingId: 1,
						consultationId: 1,
						status: '',
						timeBooked: '',
						clientSecret: data.clientSecret,
						referenceId: 'fsdgsdfgsdfg',
						transactionUrl: '',
						paymentProvider: 'stripe',
						duration: 1,
						isPaid: true,
						amount: data.amount,
						userId: data.userId,
						productId: data.productId,
					};

					//like before
					const paymentInfo = CreatePaymentInfo(createConsultationResponse);

					//Set paymentInfo object to cookies for verify payment to use when navigated
					persistPaymentInfoToCookies({
						paymentInfo,
					});

					//Navigate to payment page
					router.navigate('payment');
				});
		} catch (error) {
			console.log(error);
		}
	};

	const calculateAmountToPay = (wallet: number, cost: number): number => {
		if (wallet >= cost) {
			return 0; // Wallet covers the entire cost
		} else {
			return cost - wallet; // Deduct wallet balance from cost
		}
	};

	const handleDeleteRecording = () => {
		previousStep();
		actions.handleAddAudioRecording('');
		showNotification({
			message: `${t('tr.recording-deleted')}`,
			color: 'red',
			autoClose: 2500,
		});
	};
	const providerCurrency =provider?.currency.toString;


	return (
		<Container>
			<Flex className={styles.flexWrapper} direction="row">
				<Paper
					// className={styles.noBottomRadius}
					radius="md"
					p="sm"
					color="blue"
					pb="sm"
					shadow="md"
				>
					<Card.Section>
						<Group position="apart" noWrap={true}>
							<Stack spacing="xs" pt="xl" pl="xl" align="start">
								<Title
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.heading.subheading.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('tr.healthcare-provider').toUpperCase()}
								</Title>
								<Text
									style={{
										fontFamily: mantineConfig.mantine.global.fontFamily,
									}}
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.label.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{provider?.providerName}
								</Text>
							</Stack>
							<Text
								pr="xl"
								style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
								color={mantineConfig.mantine.text.color}
								size={
									mantineConfig.mantine.text.fontSize
								} /* weight={mantineConfig.mantine.text.fontWeight} */
							>
								{currentDate}
							</Text>
						</Group>
					</Card.Section>
					<Card.Section mt="md" pl="xl" pr="xl">
						<Group position="center">
							<Paper
								w={{ xs: '100%', sm: '60%', md: 650 }}
								withBorder={true}
								radius={{ xs: 'sm', sm: 'md', md: 'lg' }}
								shadow="sm"
							>
								<Table
									withColumnBorders={true}
									style={mantineConfig?.mantine?.table?.container}
								>
									<tbody>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.your-account-balance').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text className="responsive-table-text" weight="bold">
													{user?.wallet ? user?.wallet.balance : 0}{' '}
													{provider?.currency}
												</Text>
											</td>
										</tr>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{booking.consultationType.type.toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text className="responsive-table-text" weight="bold">
													{booking?.consultationType.price} {provider?.currency}
												</Text>
											</td>
										</tr>

										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.amount-to-pay').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text
													className="responsive-table-text text-bold"
													weight="bold"
												>
													{booking?.consultationType?.appliedDiscount ? (
														// Display discounted price with wallet deduction
														<>
															<span
																style={{
																	textDecoration: 'line-through',
																	marginRight: '8px',
																	color: 'gray',
																}}
															>
																{`${calculateAmountToPay(user?.wallet?.balance || 0, booking?.consultationType?.price || 0)} ${provider?.currency}`}
															</span>
															{`${calculateAmountToPay(user?.wallet?.balance || 0, booking?.consultationType?.appliedDiscount.discountedPrice || 0)} ${provider?.currency}`}
														</>
													) : (
														// Display original price with wallet deduction if no discount is available
														`${calculateAmountToPay(user?.wallet?.balance || 0, booking?.consultationType?.price || 0)} ${provider?.currency}`
													)}
												</Text>
											</td>
										</tr>
										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.vat-to-pay').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text
													className="responsive-table-text text-bold"
													weight="bold"
												>
													{`${calculateVAT(
														calculateAmountToPay(
															user?.wallet?.balance || 0,
															booking?.consultationType?.appliedDiscount
																? booking?.consultationType?.appliedDiscount
																		.discountedPrice
																: booking?.consultationType?.price
														),
														booking?.consultationType.vat
													)} ${provider?.currency}`}
												</Text>
											</td>
										</tr>
										{['NGN', 'GHS', 'ZAR', 'KES'].includes(
											provider?.currency
										) && (
											<tr>
												<th style={mantineConfig?.mantine?.table?.header?.th}>
													{t('tr.paystack-fee').toUpperCase()}
												</th>
												<td
													style={mantineConfig?.mantine?.table?.body?.td}
													className="table-data"
												>
													<Text
														className="responsive-table-text text-bold"
														weight="bold"
													>
														{`${calculatePaystackFee(
															calculateAmountToPay(
																user?.wallet?.balance || 0,
																booking?.consultationType?.appliedDiscount
																	? booking?.consultationType?.appliedDiscount
																			.discountedPrice
																	: booking?.consultationType?.price
															),
															provider?.currency
														)} ${provider?.currency}`}
													</Text>
												</td>
											</tr>
										)}

										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.total-amount').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text
													className="responsive-table-text text-bold"
													weight="bold"
												>
													{`${calculateTotalAmount(
														calculateAmountToPay(
															user?.wallet?.balance || 0,
															booking?.consultationType?.appliedDiscount
																? booking?.consultationType?.appliedDiscount
																		.discountedPrice
																: booking?.consultationType?.price
														),
														booking?.consultationType.vat,
														provider?.currency
													)} ${provider?.currency}`}
												</Text>
											</td>
										</tr>

										<tr>
											<th style={mantineConfig?.mantine?.table?.header?.th}>
												{t('tr.duration').toUpperCase()}
											</th>
											<td
												style={mantineConfig?.mantine?.table?.body?.td}
												className="table-data"
											>
												<Text className="responsive-table-text" weight="bold">
													{booking?.consultationType.duration} {t('tr.minutes')}
												</Text>
											</td>
										</tr>
									</tbody>
								</Table>
							</Paper>
						</Group>
					</Card.Section>

					<Divider my="md" />
					<Stack
						spacing="sm"
						pl="xl"
						pr="xl"
						align="start"
						sx={{ width: '100%' }}
					>
						{booking?.bookingFor === 'patient' ? (
							<Fragment>
								<Title
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('tr.name')}
								</Title>
								<Text
									style={{
										fontFamily: mantineConfig.mantine.global.fontFamily,
									}}
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{' '}
									{`${user?.firstName} ${user?.surName}`}
								</Text>
							</Fragment>
						) : (
							<Fragment>
								<Title
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('tr.Child-parent')}
								</Title>
								<Text
									style={{
										fontFamily: mantineConfig.mantine.global.fontFamily,
									}}
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{' '}
									{`${user?.firstName} ${user?.surName}`}
								</Text>
								<Title
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('tr.child-name')}
								</Title>
								<Text
									style={{
										fontFamily: mantineConfig.mantine.global.fontFamily,
									}}
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{' '}
									{`${child?.name}`}
								</Text>
							</Fragment>
						)}

						<Title
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>{`${t('tr.appointment-regarding')}`}</Title>
						<Text
							style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
							color={mantineConfig.mantine.text.color}
							size={mantineConfig.mantine.text.fontSize}
							weight={mantineConfig.mantine.text.fontWeight}
						>
							{' '}
							{`${booking?.bodyArea ? booking?.bodyArea.join(', ') : booking.consultationType.speciality?.name}`}
						</Text>

						<Title
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>{`${t('tr.appointment-note')}`}</Title>
						<Text
							style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
							color={mantineConfig.mantine.text.color}
							size={mantineConfig.mantine.text.fontSize}
							weight={mantineConfig.mantine.text.fontWeight}
						>
							{booking.textDetailedDescription
								? booking.textDetailedDescription
								: `${t('tr.no-notes-provided')}`}
						</Text>

						<Title
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>{`${t('tr.appointment-record')}`}</Title>
						<Text
							style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
							color={mantineConfig.mantine.text.color}
							size={mantineConfig.mantine.text.fontSize}
							weight={mantineConfig.mantine.text.fontWeight}
						>
							{booking?.audioDetailedDescription?.length > 1 ? (
								<AudioPlayer
									base64Data={booking?.audioDetailedDescription}
									onDeleteRecording={handleDeleteRecording}
								/>
							) : (
								`${t('tr.not-recorded')}`
							)}
						</Text>

						<Flex justify="flex-start" direction="column" wrap="wrap">
							<Title
								mb="xs"
								color={mantineConfig.mantine.title.color}
								size={mantineConfig.mantine.title.fontSize}
								weight={mantineConfig.mantine.title.fontWeight}
							>{`${t('tr.appointment-images')}`}</Title>

							{booking?.images?.length > 0 ? (
								<SimpleGrid cols={5}>
									{booking?.images?.map((file, index) => (
										<div key={index}>
											<Image
												src={`data:image/png;base64,${file.encodedContent}`}
												alt={`Image ${index + 1}`}
												style={{ cursor: 'pointer' }}
												height="100%"
											/>
										</div>
									))}
								</SimpleGrid>
							) : (
								<Text
									style={{
										fontFamily: mantineConfig.mantine.global.fontFamily,
									}}
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
									align="start"
								>{`${t('tr.no-images-added')}`}</Text>
							)}
						</Flex>
						<Title
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>{`${t('tr.consent')}`}</Title>
						<Text
							style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
							color={mantineConfig.mantine.text.color}
							size={mantineConfig.mantine.text.fontSize}
							weight={mantineConfig.mantine.text.fontWeight}
						>{`${t('tr.carelyo-medical-journal')}`}</Text>
					</Stack>

					<Divider my="md" className={styles.goHide} />

					<Group className={styles.goHide} position="apart" mt="xs" p="md">
						<Button
							onClick={previousStep}
							leftIcon={
								<IconCaretLeft size={mantineConfig.mantine.button.iconSize} />
							}
							color={mantineConfig.mantine.button.back.backgroundColor}
							style={{
								color: mantineConfig.mantine.button.fontColor,
							}}
						>
							{`${t('tr.back').toUpperCase()}`}
						</Button>
					</Group>
				</Paper>
				<PaymentSelect previousStep={previousStep} />
			</Flex>
		</Container>
	);
};

const CreatePaymentInfo = async (data: ICreateConsultationResponse) => {
	//Create paymentInfo object
	const paymentInfo: IPaymentInfo = {
		paymentProvider: data.paymentProvider,
		referenceId: data.referenceId,
		data: null,
	};

	//Create objects depending on paymentProvider and set to paymentInfo
	switch (paymentInfo.paymentProvider) {
		case 'paystack': {
			const paystack: IPaystackPayment = {
				transactionUrl: data.transactionUrl,
			};

			paymentInfo.data = paystack;
			break;
		}
		case 'Stripe': {
			const stripe: IStripePayment = {
				amount: data.amount,
				currency: env.VITE_DEFAULT_CURRENCY,
				successUrl: env.VITE_LOGIN_URL,
				cancelUrl: env.VITE_LOGIN_URL,
				productId: data.productId,
				consultationId: data.consultationId,
			};

			paymentInfo.data = stripe;

			try {
				const response = await axios.post(
					`${env.VITE_STRIPE_API_URL}/api/Stripe/process`,
					stripe
				);
				console.log('Stripe payment processed successfully:', response.data);
			} catch (error) {
				console.error('Error processing Stripe payment:', error);
			}
			break;
		}
		case 'wallet':
			break;
		default:
			console.log('error: ' + paymentInfo.paymentProvider);
			break;
	}

	return paymentInfo;
};

export default BookingSummary;
